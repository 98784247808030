import * as React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'gatsby';

import borrowIndexData from '../../data/borrow/index.json';
import { DefaultCollapsibleMarkdownSection, ExternalLink, InformationLayout } from '../../components';

export const BorrowIndexPageInternal = ({ mainMarkdown, header, autos, motorcycles, mortgages, recreational, creditCards, personalLoans }: typeof borrowIndexData) => {
  return (
    <InformationLayout title={header} mainMarkdown={mainMarkdown} header={header}>
      <div className="child-spacing-y-3">
        <DefaultCollapsibleMarkdownSection {...autos} />
        <DefaultCollapsibleMarkdownSection {...motorcycles} />
        <DefaultCollapsibleMarkdownSection {...mortgages} />
        <DefaultCollapsibleMarkdownSection {...recreational} />
        <DefaultCollapsibleMarkdownSection {...creditCards} />
        <DefaultCollapsibleMarkdownSection {...personalLoans} />

        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-row child-spacing-x-3">
            <Button size="lg" tag={ExternalLink} href="https://amimembernet.com/ahcu/loan-application" color="primary">Apply Online</Button>
            <Button size="lg" tag={Link} to="/rates/#loan" color="primary">Loan Rates</Button>
          </div>
        </div>
      </div>
    </InformationLayout>
  );
};

export default () => <BorrowIndexPageInternal {...borrowIndexData} />;
